@import 'admin/variables';
@import '~arctic_admin/src/scss/main';
@import 'dashboard';
@import 'button';
@import 'panel';
@import 'view_components/attribute_component';
@import 'view_components/attribute_grid_component';
@import 'view_components/tab_nav_component';

// this makes the sidebar wider so that we can use the interactive_select input
body.index #sidebar {
  width: 400px;
  right: -400px;
}

@media(min-width: 576px) {
  .formtastic .choices {
    display: inline-block !important;
    width: 50% !important;
  }
}

.choices__inner {
  background-color: white !important;
}

.choices__input {
  width: 100% !important;
  border: none !important;
}



// logfile request form

.cm-logfile-request-form {
  display: flex;
  margin-top: 1em;
  margin-bottom: 1em;
}

.cm-logfile-request-form .input-group {
  display: flex;
}

.cm-logfile-request-form .submit {
  margin-left: 1em;
}

.cm-logfile-request-form .submit input {
  height: 36px;
}

#filters_sidebar_section {
  overflow: scroll;
  max-height: 50vh;
}