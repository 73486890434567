.c-chart-checkboxes {
  display: inline-block;
  margin: 5px;
  border: 2px solid #dbdbdb; /* Tabelle: #e4eaec; */ /* Schrift: #323537; */
  border-radius: 5px;
  padding: 1px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
}

.c-chart-checkboxes:first-of-type {
  margin-left: 0;
}

.c-chart-checkboxes > input {
  vertical-align: middle
}

.c-chart-checkboxes > label {
  padding-left: 3px;
  padding-right: 3px;
  cursor: pointer
}