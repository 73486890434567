.c-active-admin-button {
  background-color: #5E6469;
  color: #fff;
  border: none;
  border-radius: 3px;
  display: inline-block;
  transition: border 0.2s linear, color 0.2s linear, width 0.2s linear, background-color 0.2s linear;
  cursor: pointer;
  padding: 5px 8px;
}

.c-active-admin-button a {
  color: #fff;
}