.hidden {
  display: none;
}

/* Styling for active tab */
.active-tab {
  color: #007BFF; /* Active text color */
  border-bottom: 2px solid #007BFF; /* Active border color */
}

.tab-nav-component {
  margin-bottom: 1em;
}

/* Styling for inactive tab */
.inactive-tab {
  color: #000; /* Inactive text color */
  border-bottom: 2px solid transparent; /* No border */
}

.tab-buttons {
  display: flex;
  border-bottom: 2px solid #ccc;
}

.tab-button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  transition: color 0.3s, border-bottom 0.3s;
}