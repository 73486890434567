
.attribute-component-label {
  font-size: 14px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.attribute-component-value {
  font-size: 14px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}